<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col :md="24">
          <el-button type="primary" size="small" class="vg_mb_8" @click="getPushList">刷新</el-button>
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" @row-dblclick="dbClickJp" class="vg_cursor">
            <el-table-column label="员工编号" prop="stff_no" />
            <el-table-column label="公司名称" prop="cptt_name" :show-overflow-tooltip="true" />
            <el-table-column label="部门名称" prop="dept_name" :show-overflow-tooltip="true" />
            <el-table-column label="小组名称" prop="dept_team_name" :show-overflow-tooltip="true" />
            <el-table-column label="员工姓名" prop="stff_name" />
            <el-table-column label="入职时间" prop="stff_enter_time" :formatter="formatDate" />
            <el-table-column label="在职状态" prop="stff_leave_time" :formatter="formatDateLeft" />
            <el-table-column label="聘用类型" prop="stff_labr_cont" :formatter="formatEmpType" width="120" />
            <el-table-column label="到期类型">
              <template slot-scope="scope">
                <div v-if="scope.row.stff_labr_cont === 4">合同到期</div>
                <div v-if="scope.row.stff_labr_cont === 5">试用期到期</div>
                <div v-if="scope.row.stff_labr_cont === 6">首签合同提醒</div>
              </template>
            </el-table-column>
            <el-table-column label="到期时间" prop="stff_labr_expire_time" :formatter="formatDate1" />
            <el-table-column label="操作" align="center" width="80px">
              <template slot-scope="scope">
                <el-button type="text" size="mini" @click="toCompleted(scope.$index, scope.row)" v-show="scope.row.scan">完成</el-button>
                <span v-show="!scope.row.scan" class="vd_completed">已完成</span>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { stffAPI } from '@api/modules/staff';
export default {
  name: 'StffPush9001',
  data() {
    return {
      tableData: [],
      loading: true,
      isEditObj: []
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    for (let i in this.tableData) {
      let scan = 'edit' + i;
      this.isEditObj.push({
        scan: true
      });
    }
  },
  methods: {
    initData() {
      this.getPushList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDay(row.stff_enter_time);
    },
    formatDate1(row) {
      return this.helper.toStringDay(row.stff_labr_expire_time);
    },
    // 员工离职
    formatDateLeft(row) {
      if (row.stff_leave_time === 0) {
        return '在职';
      } else {
        return this.helper.toTimeDay(row.stff_leave_time) + '离职';
      }
    },
    // 聘用类型
    formatEmpType(row) {
      if (row.stff_labr_cont === 5) {
        return '试用';
      } else if (row.stff_labr_cont === 1) {
        return '返聘';
      } else if (row.stff_labr_cont === 2) {
        return '实习';
      } else if (row.stff_labr_cont === 3) {
        return '劳务';
      } else if (row.stff_labr_cont === 4) {
        return '正式';
      } else {
        return '无生效合同';
      }
    },
    //获取信息
    getPushList() {
      get(stffAPI.getPushStffs9001).then(res => {
        if (res.data.code === 0) {
          this.loading = false;
          this.tableData = res.data.data.list;
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        }
      });
    },
    //操作
    toCompleted(index, row) {
      post(stffAPI.updateScanById, { stff_id: row.stff_id })
        .then(res => {
          if (res.data.code === 0) {
            this.$set(row, 'scan', false);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 双击
    dbClickJp(row) {
      this.jump('/stff_edit', { perm_id: this.$route.query.perm_id, form_id: row.stff_id });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_completed {
  color: $color-secondary-text;
}
</style>
